import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import Twitter from "../components/svg/twitter"
import Linkedin from "../components/svg/linkedin"

const AuthorPage = () => (
    <Layout>
        <Seo 
            title="Shaun Symm" 
            description="Shaun Symm is the Managing Director at Launch Lab Australia - a web design and web development company in Sydney and Canberra."
        />
        <section role="main" className="bg-purple with-pad-sm white">
        <div className="container">
            <div className="row flex flex-jc">
                <div className="hero-content sub-hero">
                    <h1
                    className="hero-lead"
                    data-sal="slide-up" 
                    data-sal-easing="ease"
                    data-sal-delay="100"
                    data-sal-duration="400"
                    >Shaun Symm</h1>
                    <h2 className="hero-lead"
                    data-sal="slide-up" 
                    data-sal-easing="ease"
                    data-sal-delay="200"
                    data-sal-duration="400"
                    >
                        Managing Director, Launch Lab
                    </h2>
                    <div className="btn-row">
                    <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                        <span className="flex flex-ac down">
                        <ArrowRight />
                        </span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>My role</h3>
                            <p>View my personal website - <a href="http://shaunsymm.com/">Shaun Symm</a></p>
                            <div className="flex founder-socials">
                                <a href="https://twitter.com/shaunsymm" className="about-icon twitter-icon mr-1">
                                    <Twitter />
                                </a>
                                <a href="https://au.linkedin.com/in/shaunsymm" className="about-icon linkedin-icon">
                                    <Linkedin />
                                </a>
                            </div>
                        </div>

                        <div className="col-67 long-text">
                            <p>Don't let the title 'Managing Director' fool you.</p>
                            <p>Launch Lab helps startups get started and in doing this we follow a lean methodology just like we advise startups to do.</p>
                            <p>That means I get my hands dirty. I don't just manage the business, I also do the following:</p>
                            <h4>Startup Consulting</h4>
                            <p>I've helped develop more than 40 startup applications. In the past 8 years I've also spoken to more than 500 startup founders who are at the 'back-of-a-napkin' stage. Experience tells me that every startup will face different challenges and so nobody knows it all. I do however see common mistakes and try to guide the startups we work with away from poor decisions.</p>
                            <p>You can find more of these thoughts in our award winning <Link to="/blog/">startup blog</Link>.</p>
                            <h4>Project management</h4>
                            <p>I project manage the design and development of websites, startups and apps.</p>
                            <h4>Wireframing and rapid prototyping</h4>
                            <p>I work with our clients to determine the user experience and user flows throught their products.</p>
                            <h4>Web design</h4>
                            <p>I spend a lot of time designing websites and startup applications in Figma.</p>
                            <h4>Frontend web development</h4>
                            <p>These days I stick purely to the visual aspects of the products we develop and so I spend a lot of my time writing CSS and HTML / JSX. I leave the Javascript work to my experienced team.</p>
                            <h4>Webflow development</h4>
                            <p>While I love writing my own CSS, I also love using Webflow to build websites and content management solutions.</p>
                            <h4>Digital marketing</h4>
                            <p>I'm Google Ads certified and very knowledgeable on SEO. While Launch Lab isn't a digital marketing company, having these skills helps when advising our clients and when I'm developing their websites (to optimise SEO or improve landing page conversions).</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <Cta text="If I can assist with a web design, web development or startup development quote please contact us. If you're an early stage startup founder, who is just getting started, contact us and I can help offer advice on getting started." />
    </Layout>
)

export default AuthorPage